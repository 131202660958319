





















import { Component, Mixins } from 'vue-property-decorator'

import { ViewDataScope } from '@/modules/shared/entities/ViewData'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

import Headline from '@/modules/shared/components/Headline.vue'
import PerspectiveList from '../components/PerspectiveList.vue'

@Component({
  components: {
    Headline,
    PerspectiveList,
  },
})
export default class Perspective extends Mixins(ViewDataMixin) {
  viewDataScope = ViewDataScope.perspective
}
